import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
function ConfirmationDialogRaw(props) {
  // console.log(props);
  const { onClose, value: valueProp, open, ...other } = props;
  // const [value, setValue] =  useState(valueProp);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  //  useEffect(() => {
  //   if (!open) {
  //     setValue(valueProp);
  //   }
  // }, [valueProp, open]);

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());

          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_BASE_URL}users/add-user-details`,
              {
                method: "POST",
                body: JSON.stringify(formJson),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.ok) {
              const result = await response.json();
              console.log("Saved successfully:", result);
              toast.success(result.message);
              onClose();
              navigate("/dashboard");
            } else {
              const error = await response.json();

              toast.error(error.message);
            }
          } catch (error) {
            console.error("Error:", error);
          }
        },
      }}
    >
      <Toaster />
      <DialogTitle>Add Person Details</DialogTitle>
      <DialogContent dividers>
        {" "}
        <div>
          <TextField
            required
            id="first_name"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            margin="dense"
          />{" "}
        </div>{" "}
        <div>
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            margin="dense"
          />{" "}
        </div>{" "}
        <div>
          <TextField
            required
            id="passport_number"
            name="passport_number"
            label="Passport Number"
            type="text"
            fullWidth
            margin="dense"
            inputProps={{
              pattern: "^[^\\s]*$",
              minLength: 6,
              maxLength: 10,
            }}
          />{" "}
        </div>
      </DialogContent>
      <DialogActions>
        {" "}
        <Button type="submit">Save</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function AddUserDialog({
  openProp,
  handleClose,
  title,
  message,
  redirectUrl,
}) {
  const [open, setOpen] = useState(openProp);

  useEffect(() => {
    setOpen(openProp); // Update open state when openProp changes
  }, [openProp]); // Dependency array ensures this runs when openProp changes

  const handleCloseDialog = (newValue) => {
    setOpen(false);

    if (newValue) {
      handleClose(newValue); // Pass new value back to parent if needed
    } else {
      handleClose();
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <List component="div" role="group">
        <ConfirmationDialogRaw
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleCloseDialog}
          value={message}
        />
      </List>
    </Box>
  );
}

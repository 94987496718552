import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import pattern from "assets/images/pattern-tree.svg";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import MyDocument from "components/PDF";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
};
function Profile() {
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [user_id, setUserId] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [passport_number, setPassportNumber] = useState("");
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    passport_number: "",
  });
  console.log(setErrors);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails || "");
      setUserId(userDetails._id || "");
      setFirstName(userDetails.first_name || "");
      setLastName(userDetails.last_name || "");
      setPassportNumber(userDetails.passport_number || "");
    }
  }, [userDetails]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <Toaster />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                  <Card
                    sx={({
                      palette: { gradients },
                      functions: { linearGradient },
                      boxShadows: { xl },
                    }) => ({
                      background: gradients["dark"]
                        ? linearGradient(
                            gradients["dark"].main,
                            gradients["dark"].state
                          )
                        : linearGradient(
                            gradients.dark.main,
                            gradients.dark.state
                          ),
                      boxShadow: xl,
                      position: "relative",
                    })}
                  >
                    <MDBox
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      opacity={0.2}
                      sx={{
                        backgroundImage: `url(${pattern})`,
                        backgroundSize: "cover",
                      }}
                    />
                    <MDBox position="relative" zIndex={2} p={2}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {" "}
                        <MDBox
                          color="white"
                          p={1}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <Icon fontSize="default">person</Icon>
                        </MDBox>
                        <MDBox ml="auto" lineHeight={0}>
                          <MDButton
                            variant="contained"
                            color="white"
                            onClick={handleClickOpen}
                            disabled={
                              userData &&
                              (userData.status === "Under_review" ||
                                userData.status === "Processing" ||
                                userData.status === "Block" ||
                                userData.status === "Rejected" ||
                                userData.status === "Completed")
                                ? true
                                : false
                            }
                          >
                            <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                            &nbsp;Edit Profile
                          </MDButton>
                        </MDBox>
                      </MDBox>
                      <MDTypography
                        variant="h6"
                        color="white"
                        fontWeight="medium"
                        sx={{ mt: 2, mb: 3, pb: 1 }}
                      >
                        {userData ? userData.address : "Loading..."}
                      </MDTypography>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <MDBox display="flex" alignItems="center">
                          <MDBox mr={3} lineHeight={1}>
                            <MDTypography
                              variant="button"
                              color="white"
                              fontWeight="regular"
                              opacity={0.8}
                            >
                              First Name
                            </MDTypography>
                            <MDTypography
                              variant="h6"
                              color="white"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {userData ? userData.first_name : "-"}
                            </MDTypography>
                          </MDBox>
                          <MDBox mr={3} lineHeight={1}>
                            <MDTypography
                              variant="button"
                              color="white"
                              fontWeight="regular"
                              opacity={0.8}
                            >
                              Last Name
                            </MDTypography>
                            <MDTypography
                              variant="h6"
                              color="white"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {userData ? userData.last_name : "-"}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox>
                          <MDTypography
                            variant="button"
                            color="white"
                            fontWeight="regular"
                            opacity={0.8}
                          >
                            Passport Number
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {userData ? userData.passport_number : "-"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <Card>
                    <MDBox p={2} mx={3} display="flex" justifyContent="center">
                      <MDBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="success"
                        color="white"
                        width="4rem"
                        height="4rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">paid</Icon>
                      </MDBox>
                    </MDBox>
                    <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        Payment Status
                      </MDTypography>

                      {/* <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        Transaction summary and payment successrmation.
                      </MDTypography> */}

                      <Divider />
                      {userData && userData.paymentStatus ? (
                        userData.paymentStatus === "PReceived" ? (
                          "Payment Received"
                        ) : userData.paymentStatus === "PNotReceived" ? (
                          "Payment Not Received"
                        ) : (
                          userData.paymentStatus || "-"
                        )
                      ) : (
                        <MDTypography variant="h5" fontWeight="medium">
                          Pending
                        </MDTypography>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <Card>
                    <MDBox p={2} mx={3} display="flex" justifyContent="center">
                      <MDBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="success"
                        color="white"
                        width="4rem"
                        height="4rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">pending_actions</Icon>
                      </MDBox>
                    </MDBox>
                    <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        User Status
                      </MDTypography>

                      {/* <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        Overview of current status details.
                      </MDTypography> */}

                      <Divider />

                      {/* <MDTypography variant="h5" fontWeight="medium">
                        Pending
                      </MDTypography> */}

                      {userData && userData.status ? (
                        userData.status === "Under_review" ? (
                          "Under Review"
                        ) : userData.status === "Processing" ? (
                          "Approved! Creating NFT Soon 😊"
                        ) : (
                          userData.status || "Pending"
                        )
                      ) : (
                        <MDTypography variant="h5" fontWeight="medium">
                          Pending
                        </MDTypography>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card id="delete-account">
                    <MDBox
                      pt={2}
                      px={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" fontWeight="medium">
                        Payment and Status Details
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            textAlign="center"
                            mb={2}
                          >
                            Payment Details
                          </MDTypography>
                          {userData && userData.paymentStatus ? (
                            <MDBox
                              position="relative"
                              zIndex={2}
                              p={2}
                              borderRadius="lg"
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <MDTypography
                                variant="h6"
                                color="dark"
                                fontWeight="medium"
                              >
                                Transaction Hash:{" "}
                                <span style={{ fontSize: 14, color: "gray" }}>
                                  {userData.paymentHash || "-"}
                                </span>
                              </MDTypography>
                              <MDTypography
                                variant="h6"
                                color="dark"
                                fontWeight="medium"
                              >
                                Payment Status:{" "}
                                <span style={{ fontSize: 14, color: "gray" }}>
                                  {userData.paymentStatus === "PReceived"
                                    ? "Payment Received"
                                    : userData.paymentStatus === "PNotReceived"
                                    ? "Payment Not Received"
                                    : userData.paymentStatus || "-"}
                                </span>
                              </MDTypography>
                              <MDTypography
                                variant="h6"
                                color="dark"
                                fontWeight="medium"
                              >
                                Coupon Available:{" "}
                                <span style={{ fontSize: 14, color: "gray" }}>
                                  {userData.isCoupon === true
                                    ? "Available"
                                    : "Not Available"}
                                </span>
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDBox
                              position="relative"
                              zIndex={2}
                              p={2}
                              textAlign="center"
                              borderRadius="lg"
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <MDTypography
                                variant="h4"
                                color="dark"
                                fontWeight="medium"
                                mb={2}
                                mt={3}
                              >
                                Payment Process Incomplete
                              </MDTypography>
                              <MDBox ml="auto" lineHeight={0} color="dark">
                                <MDTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  Payment is pending. Proceed to complete it in
                                  the payment section.
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            textAlign="center"
                            mb={2}
                          >
                            Status Details
                          </MDTypography>
                          <MDBox
                            position="relative"
                            zIndex={2}
                            p={2}
                            textAlign="center"
                            borderRadius="lg"
                            sx={{
                              border: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            }}
                          >
                            <MDTypography
                              variant="h4"
                              color="dark"
                              fontWeight="medium"
                              mb={2}
                              mt={3}
                            >
                              {userData?.status
                                ? userData.status === "Under_review"
                                  ? "Under Review"
                                  : userData.status === "Processing"
                                  ? "Approved! Creating NFT Soon 😊"
                                  : userData.status
                                : "Document Submission Incomplete"}
                            </MDTypography>
                            <MDBox ml="auto" lineHeight={0} color="dark">
                              <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                              >
                                Your profile is currently under process. We will
                                get back to you later.
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card sx={{ height: "100%" }}>
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Face Recognition
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {userData ? formatDate(userData.updatedAt) : "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox p={2}>
                  {userData &&
                  userData.faceDocument &&
                  userData.faceDocument ? (
                    <Grid item xs={12}>
                      <MDBox
                        borderRadius="lg"
                        p={3}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox>
                          <img
                            src={userData.faceCaptureUrls}
                            alt="User"
                            style={{
                              maxWidth: "100%",
                              borderRadius: "8px",
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <MDBox
                        borderRadius="lg"
                        p={3}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox textAlign="center" mb={2}>
                          <MDTypography variant="h5" fontWeight="medium">
                            Face Recognition
                          </MDTypography>
                          <MDTypography variant="body2">
                            No live face capture has been posted as of yet.
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card id="delete-account">
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={3}
                  px={2}
                >
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Your Uploaded Passport Document
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {userData ? formatDate(userData.updatedAt) : "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mb={1}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                          mb={2}
                        >
                          {userData &&
                          userData.documents &&
                          userData.documents ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={1}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <MyDocument pdfFile={userData.documentUrls} />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="medium"
                                  >
                                    Passport
                                  </MDTypography>
                                  <MDTypography variant="body2">
                                    No document has been uploaded yet.
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: "100%" }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={3}
                  px={2}
                >
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Your Uploaded Photo
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {userData ? formatDate(userData.updatedAt) : "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mb={1}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                          mb={2}
                        >
                          {userData &&
                          userData.photoDocument &&
                          userData.photoDocument ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <img
                                    src={userData.photoUrls}
                                    alt="User"
                                    style={{
                                      maxWidth: "100%",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="medium"
                                  >
                                    Photo
                                  </MDTypography>
                                  <MDTypography variant="body2">
                                    No photo has been uploaded yet.
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}users/update-user-details/${user_id}`,
                {
                  method: "POST",
                  body: JSON.stringify(formJson),
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (response.ok) {
                const result = await response.json();
                toast.success(result.message);
                dispatch(getUserProfileData(token));
                handleClose();
                navigate("/profile");
              } else {
                const error = await response.json();
                toast.error(error.message);
              }
            } catch (error) {
              console.error("Error:", error);
            }
          },
        }}
      >
        <DialogTitle>Update Person Details</DialogTitle>
        <DialogContent dividers>
          {" "}
          <TextField
            required
            id="first_name"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            margin="dense"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!errors.first_name}
          />{" "}
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            margin="dense"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
            error={!!errors.last_name}
          />{" "}
          <TextField
            required
            id="passport_number"
            name="passport_number"
            label="Passport Number"
            type="text"
            fullWidth
            margin="dense"
            value={passport_number}
            onChange={(e) => setPassportNumber(e.target.value)}
            inputProps={{
              pattern: "^[^\\s]*$",
              minLength: 6,
              maxLength: 10,
            }}
            error={!!errors.passport_number}
            helperText={
              errors.passport_number ||
              "No spaces allowed, and length should be between 6 to 9 characters"
            }
          />{" "}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton type="submit" color="info">
            Update
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Profile;

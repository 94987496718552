import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requeststatus: "idle",
  userDetails: [],
  tempDetails: [],
  loading: true,
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
  currentRole: (JSON.parse(localStorage.getItem("user")) || {}).role || null,
  currentAuthToken: JSON.parse(localStorage.getItem("userToken")) || null,
  error: null,
  response: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authRequest: (state) => {
      state.loading = true;
      state.requeststatus = "loading";
    },
    underControl: (state) => {
      state.requeststatus = "idle";
      state.response = null;
    },
    stuffAdded: (state, action) => {
      state.requeststatus = "added";
      state.response = null;
      state.error = null;
      state.tempDetails = action.payload;
    },

    authSuccess: (state, action) => {
      console.log(state);
      console.log(action);
      state.requeststatus = "idle";
      state.currentUser = action.payload;
      state.currentRole = action.payload.role;
      state.response = null;
      state.error = null;
      state.loading = false;
      localStorage.removeItem("userToken");
    },
    authFailed: (state, action) => {
      state.requeststatus = "failed";
      state.response = action.payload;
    },

    authError: (state, action) => {
      console.log(action);
      state.requeststatus = "error";
      state.error = action.payload;
    },

    otpSuccess: (state, action) => {
      state.requeststatus = "success";
      state.currentAuthToken = action.payload.token;
      state.currentUser = action.payload.user;
      state.currentRole = action.payload.user.role;
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("userToken", JSON.stringify(action.payload.token));
      state.response = null;
      state.error = null;
    },
    otpFailed: (state, action) => {
      state.requeststatus = "failed";
      state.response = action.payload;
    },

    otpError: (state, action) => {
      console.log(action);
      state.requeststatus = "error";
      state.error = action.payload.message;
    },
    authLogout: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      state.currentUser = null;
      state.requeststatus = "idle";
      state.error = null;
      state.currentRole = null;
      state.currentAuthToken = null;
    },

    doneSuccess: (state, action) => {
      state.userDetails = action.payload.data;
      state.tempDetails = action.payload.data;
      state.loading = false;
      state.error = null;
      state.response = null;
    },
    getDeleteSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.response = null;
    },

    getRequest: (state) => {
      state.loading = true;
    },
    getFailed: (state, action) => {
      state.response = action.payload;
      state.loading = false;
      state.error = null;
    },
    getError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  authRequest,
  underControl,
  stuffAdded,
  authSuccess,
  authFailed,
  authError,
  authLogout,
  doneSuccess,
  getDeleteSuccess,
  getRequest,
  getFailed,
  getError,
  otpSuccess,
  otpFailed,
  otpError,
} = userSlice.actions;

export const userReducer = userSlice.reducer;

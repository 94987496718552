import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import DeniedImage from "assets/images/icons/Denied.png";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DialogContentText from "@mui/material/DialogContentText";

function ConfirmationDialogRaw(props) {
  // console.log(props);
  const { onClose, value: valueProp, open, ...other } = props;

  const navigate = useNavigate();

  const onClickHandleLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("nftdata");
    navigate("/login");
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <MDBox display="flex" flexDirection="column" alignItems="center" p={6}>
        <img
          alt="document"
          src={DeniedImage}
          width={80}
          style={{ marginBottom: "16px" }}
        />
        <DialogTitle id="error-dialog-title" align="center">
          Logout Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description" align="center">
            Your payment hash is invalid. To resolve this issue, please log out
            and log back in to refresh your session and ensure proper
            functionality.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={onClickHandleLogout} color="info">
            Ok
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function PaymentHashErrorMsg({ openProp, handleClose }) {
  const [open, setOpen] = useState(openProp);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  const handleCloseDialog = (newValue) => {
    setOpen(false);

    if (newValue) {
      handleClose(newValue);
    } else {
      handleClose();
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <List component="div" role="group">
        <ConfirmationDialogRaw
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleCloseDialog}
          value="Logout from this screen"
        />
      </List>
    </Box>
  );
}

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { useEffect, useState, useCallback } from "react";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DocumentImage from "assets/images/smallIcons/img1.png";
import FaceImage from "assets/images/smallIcons/img2.png";
import PhotoImage from "assets/images/smallIcons/img3.png";
import StatusImage from "assets/images/smallIcons/img5.png";
import PaymentImage from "assets/images/smallIcons/img4.png";
import MDButton from "components/MDButton";
import ProgressBar from "components/ProgressBar";
import DocumentDarkImage from "assets/images/icons/selected/Document.png";
import CameratDarkImage from "assets/images/icons/selected/Camera.png";
import PhotoDarkImage from "assets/images/icons/selected/Vector.png";
import PaymentDarkImage from "assets/images/icons/selected/beta.png";
import StatusDarkImage from "assets/images/icons/selected/Vector-1.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HandshakeImage from "assets/images/handshake2.png";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";

import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [userPaymentData, setUserPaymentData] = useState(null);
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const dispatch = useDispatch();

  const { userDetails, loading } = useSelector((state) => state.user);

  useEffect(() => {
    // setLoading(true);
    if (token) {
      dispatch(getUserProfileData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails);
      // setLoading(false);
    }
  }, [userDetails]);

  const fetchUserCouponData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/checkCouponForUser`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const results = await response.json();
      setUserPaymentData(results.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Upload failed. Please try again.";
      console.log(errorMessage);
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        navigate("/");
        return;
      }
      await fetchUserCouponData();
    };
    fetchData();
  }, [token, navigate, fetchUserCouponData]);

  useEffect(() => {
    if (userData && userData.status === "Completed") {
      setIsSuccessDialogOpen(true);
    }
  }, [userData]);

  const rawNftData = localStorage.getItem("nftdata");
  const nftMinting = !isNaN(rawNftData) ? rawNftData : 0;
  // console.log(loading);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {nftMinting > 0 ? (
        <MDBox py={3}>
          {loading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress size={60} color="primary" />
              <Typography variant="h6" ml={2}>
                Loading your data...
              </Typography>
            </MDBox>
          ) : (
            <>
              <MDBox display="flex" justifyContent="center" mb={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="center">
                      <Marquee gradient={false} speed={130}>
                        <Alert
                          severity="success"
                          variant="outlined"
                          sx={{ borderWidth: 3 }}
                        >
                          <AlertTitle>
                            Awesome! Your NFT Is Now Minted and Secured!
                          </AlertTitle>
                          Your digital asset is officially minted and recorded.
                          Ready to stand out in the NFT marketplace!
                        </Alert>
                      </Marquee>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <ProgressBar activeStep={5} />
              <MDBox>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid #3A8A11`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img alt="Icon" src={DocumentDarkImage} />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              flexGrow: 1,
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              sx={{
                                color: "#3A8A11",
                              }}
                            >
                              Document Verification
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid #3A8A11`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img alt="Icon" src={CameratDarkImage} />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: "#3A8A11",
                              }}
                            >
                              Face Verification
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid #3A8A11`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img alt="Icon" src={PhotoDarkImage} />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: "#3A8A11",
                              }}
                            >
                              Upload Photo
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid #3A8A11`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img alt="Icon" src={PaymentDarkImage} />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: "#3A8A11",
                              }}
                            >
                              Payment Process
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid #3A8A11`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img alt="Icon" src={StatusDarkImage} />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: "#3A8A11",
                              }}
                            >
                              Check Status
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox textAlign="center" mt={10}>
                  <MDBox>
                    <Typography variant="h1">
                      Your Token ID is {nftMinting}
                    </Typography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </>
          )}
        </MDBox>
      ) : (
        <MDBox py={3}>
          {loading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress size={60} color="primary" />
              <Typography variant="h6" ml={2}>
                Loading your data...
              </Typography>
            </MDBox>
          ) : (
            <>
              {userData?.status ? (
                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="center">
                        <Marquee gradient={false} speed={130}>
                          <Alert
                            severity="info"
                            variant="outlined"
                            sx={{ borderWidth: 3 }}
                          >
                            <AlertTitle>
                              {{
                                Under_review: "Under Review",
                                Processing: "Creating NFT",
                                Block: "Block",
                                Resubmission: "Resubmission",
                                Rejected: "Rejected",
                                Completed: "Completed",
                              }[userData.status] || ""}
                            </AlertTitle>
                            {{
                              Under_review:
                                "We appreciate your patience as we review your documents; an administrator will be in contact with you soon.",
                              Processing: "Approved! Creating NFT Soon 😊",
                              Block:
                                "You have been blocked by the administrator; please get in touch with our help desk. ",
                              Resubmission:
                                "Please make sure your documents are in clear view so the admin can quickly inspect and confirm that.",
                              Rejected:
                                "You are no longer able to upload documents since the admin rejected you. ",
                              Completed:
                                "We are happy 😊 to announce that after reviewing your documents. You have successfully passed the KYC. We will be providing the NFT soon to the wallet address you have registered.",
                            }[userData.status] || ""}
                          </Alert>
                        </Marquee>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              ) : userPaymentData?.code !== 0 ? (
                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="center">
                        <Marquee gradient={false} speed={130}>
                          <Alert
                            severity="info"
                            variant="outlined"
                            sx={{ borderWidth: 3 }}
                          >
                            <AlertTitle>Coupon Applied</AlertTitle>
                            Coupon Applied Successfully 😊, Enjoy the ICB KYC
                            without Payment
                          </Alert>
                        </Marquee>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              ) : null}

              <ProgressBar
                activeStep={userData && userData.status === "Completed" ? 5 : 0}
              />

              <MDBox>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? DocumentDarkImage
                                    : DocumentImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              flexGrow: 1,
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Document Verification
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? CameratDarkImage
                                    : FaceImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Face Verification
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? PhotoDarkImage
                                    : PhotoImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Upload Photo
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? PaymentDarkImage
                                    : PaymentImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Payment Process
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? StatusDarkImage
                                    : StatusImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Check Status
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox textAlign="center" mt={10}>
                  <MDBox>
                    <Typography variant="h1">Welcome to ICB KYC</Typography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Please upload the related documents to continue
                    </MDTypography>
                  </MDBox>

                  <MDBox>
                    <Button
                      variant="contained"
                      size="large"
                      component={Link}
                      to="/documents"
                      sx={{
                        background:
                          "linear-gradient(94deg, #50811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "darkgreen",
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Dialog
                    open={isSuccessDialogOpen}
                    onClose={handleDialogSuccessClose}
                    aria-labelledby="error-dialog-title"
                    aria-describedby="error-dialog-description"
                    fullWidth={true} // Ensures the dialog stretches as per the content
                  >
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      p={6}
                    >
                      <img alt="document" src={HandshakeImage} width={100} />

                      <DialogTitle id="error-dialog-title" align="center">
                        Congratulation! 🥳
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText
                          id="error-dialog-description"
                          align="center"
                        >
                          We are happy 😊 to announce that after reviewing your
                          documents. You have successfullly based the KYC. We
                          will be providing NFT Soon on the wallet address you
                          have resigtered.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <MDButton
                          onClick={handleDialogSuccessClose}
                          variant="contained"
                          size="large"
                          sx={{
                            background:
                              "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "darkgreen",
                            },
                          }}
                        >
                          OK
                        </MDButton>
                      </DialogActions>
                    </MDBox>
                  </Dialog>
                </MDBox>
              </MDBox>
            </>
          )}
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import homeImage from "assets/images/Homepage/img1.png";
import { useMediaQuery } from "react-responsive";
import MDBox from "components/MDBox";
import { Grid, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AddUserDialog from "components/Dialog/AddUserDetails";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";

function HomePage() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const rawNftData = localStorage.getItem("nftdata");
  const nftMinting = !isNaN(rawNftData) ? rawNftData : 0;

  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const { userDetails, error } = useSelector((state) => state.user);
  console.log("errorwdsd", error);
  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (error && error.statusCode === 401) {
      navigate("/login");
    }
  }, [error, navigate]);

  useEffect(() => {
    if (nftMinting > 0) {
      navigate("/dashboard");
    } else {
      navigate("/home");
    }

    if (userDetails) {
      if (userDetails.isProfileInfo === false) {
        setIsSuccessDialogOpen(true); // Open dialog if isProfileInfo is false
      } else {
        setIsSuccessDialogOpen(false); // Close dialog if isProfileInfo is true
      }
    }
  }, [navigate, dispatch, userDetails, nftMinting]);

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            {!isMobile ? (
              <MDBox display="flex" justifyContent="center" mt={4} mb={4}>
                <img alt="Waving Woman" src={homeImage} />
              </MDBox>
            ) : (
              <MDBox display="flex" justifyContent="center" mt={2} mb={2}>
                <img alt="Waving Woman" src={homeImage} width="70%" />
              </MDBox>
            )}

            {/* Text */}
            <MDBox textAlign="center" mb={2}>
              <Typography variant="h1">Hi there! Let's begin</Typography>
            </MDBox>

            {/* Start Button */}
            <MDBox textAlign="center">
              <Button
                variant="contained"
                size="large"
                component={Link} // Link is added as the component
                to="/dashboard"
                sx={{
                  background:
                    "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
              >
                START
              </Button>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <AddUserDialog
        openProp={isSuccessDialogOpen}
        handleClose={handleDialogSuccessClose}
        title="Success"
        message="success"
        redirectUrl="/upload-photo"
      />
    </DashboardLayout>
  );
}

export default HomePage;

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import logo from "assets/images/logo.png";
import { useMediaQuery } from "react-responsive";
function BasicLayout({ image, children }) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      {/* Logo Container */}
      {!isMobile && (
        <MDBox position="absolute" top={30} left={30} zIndex={1000}>
          <img src={logo} alt="Logo" style={{ width: "190px" }} />
        </MDBox>
      )}
      {/* <MDBox position="absolute" top={30} left={30} zIndex={1000}>
        <img src={logo} alt="Logo" style={{ width: "150px" }} />{" "}
      </MDBox> */}

      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={6} lg={5} xl={5}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;

import { useState, useRef } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMediaQuery } from "react-responsive";
import bgImage from "assets/images/bg.png";
import toast, { Toaster } from "react-hot-toast";
import icon from "assets/images/icon.png";
import logo from "assets/images/logo.png";
import Paper from "@mui/material/Paper";
import ReCAPTCHA from "react-google-recaptcha";
import MDButton from "components/MDButton";
import Input from "@mui/material/Input";
function SearchAddress() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState(false);
  const [wallet_address, setWalletAddress] = useState("");
  //   const [recaptchaToken, setRecaptchaToken] = useState(null);

  const recaptchaRef = useRef();

  //   const handleCaptchaChange = (token) => {
  //     setRecaptchaToken(token);
  //   };

  const onClickHandle = async (e) => {
    e.preventDefault();
    const captchaValue = recaptchaRef.current.getValue();

    if (!captchaValue) {
      toast.error("Please complete the CAPTCHA verification.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/getSearchAddressList?address=${wallet_address}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Add this header
          },
          body: JSON.stringify({ captchaValue }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        if (result.data.status === "Accepted") {
          toast.success(
            "We have accepted your KYC request and we are processing on it."
          );
        } else if (result.data.status === "Resubmit") {
          toast.success(
            "Your document has to be submitted again because it is unclear and difficult to validate."
          );
        } else if (result.data.status === "Rejected") {
          toast.success(
            "The admin has rejected the document you submitted. Please get in touch with our support staff."
          );
        } else {
          toast.success("Address found successfully.");
        }
      } else if (result.message) {
        recaptchaRef.current.reset();
        toast.error(result.message);
      } else {
        toast.error(
          "There is no record of this user wallet address in the ICB KYC account."
        );
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while searching the address.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Toaster />

      <Card
        sx={{
          backgroundColor: "#151515",
          boxShadow: 3,
          mx: 1,
          mt: -3,
          p: 2,
          mb: 3,
          textAlign: "center",
          border: "1px solid green",
          width: "100%",
        }}
      >
        {loading && <div className="loader"></div>}

        <MDBox>
          {!isMobile ? (
            <MDBox component="img" src={icon} alt="Logo" width="4rem" />
          ) : (
            <MDBox component="img" src={logo} alt="Logo" width="10rem" />
          )}

          <MDTypography
            variant="h2"
            fontWeight="medium"
            color="white"
            mt={2}
            mb={3}
          >
            ICB KYC
          </MDTypography>

          <form onSubmit={onClickHandle}>
            <Paper
              component="div"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                sx={{
                  ml: 1,
                  flex: 1,
                  fontSize: 22,
                  padding: "19px 12px", // Increase the padding for a larger box
                  borderBottom: "none", // Remove the default underline
                  borderRadius: "8px", // Add border radius for rounded corners
                  backgroundColor: "white", // Add background color to the input
                }}
                placeholder="Search wallet address"
                value={wallet_address}
                onChange={(e) => setWalletAddress(e.target.value)}
                required
              />
            </Paper>
            <MDBox
              mb={2}
              mt={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",

                height: "100%",
              }}
            >
              {/* <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_SITE_KEY}
                size="normal"
                onChange={handleCaptchaChange}
              /> */}

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_SITE_KEY}
                size="normal"
              />
            </MDBox>

            <MDBox>
              <MDButton
                variant="contained"
                size="large"
                type="submit"
                sx={{
                  background:
                    "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
              >
                Search
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SearchAddress;
